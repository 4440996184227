import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpLoaderService {

  readonly isLoading = new BehaviorSubject(false);
  readonly isError = new BehaviorSubject({
    isError: false,
    error: {} as HttpErrorResponse
  });

  constructor() {}
}
