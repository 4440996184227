import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseService } from './base.service';
import { Configuration } from './configuration';
import { AuthService } from './auth.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
@Injectable()
export class CallService extends BaseService {

	constructor(http: HttpClient, test: AuthService, localStorageService: LocalStorageService) {
    	super(http, localStorageService);
    	test.isAuthenticated();
	}
	getContact () {
		return this.getData(Configuration.BASE_URL + Configuration.PERSON);
	}
}
