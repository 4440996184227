import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import * as moment from 'moment';

@Injectable()
export class AuthService {

  constructor(private localStorageService: LocalStorageService, private router: Router ) { }

  public isAuthenticated(): boolean {
    const token = this.localStorageService.retrieve('token');
    const expiredToketAt = this.localStorageService.retrieve('expired_token_at');
    const unixCurrTime = moment().unix();

    const url = this.router.url
    // this.attribute = this.storage.retrieve('boundValue');
    if (token == null || unixCurrTime > expiredToketAt) {
      if (url.indexOf('login') != -1 || url.indexOf('activate-user') != -1 || url.indexOf('forgot-password') != -1 || url.indexOf('reset-password') != -1) {
        return false
      } else {
        alert('Your Session Might Be Over, Please Login Again!');
      }
      return false;
    } else {
      return true;
    }
    // return !this.jwtHelper.isTokenExpired(token);
  }

  public getToken() {
    return this.localStorageService.retrieve('token');
  }

  public getUsername() {
    return this.localStorageService.retrieve('username');
  }

  public getRoleAuth() {
    return this.localStorageService.retrieve('role');
  }

  public isAuthenticateRole(role) {
    return this.arrayMatch(this.getRoleAuth(), role).length > 0 ? true : false
  }

  private arrayMatch(arr1, arr2) {
    var arr = [];  // Array to contain match elements
  
    for(var i=0 ; i<arr1.length ; ++i) {
      for(var j=0 ; j<arr2.length ; ++j) {
        if(arr1[i] == arr2[j]) {    // If element is in both the arrays
          arr.push(arr1[i]);        // Push to arr array
        }
      }
    }
     
    return arr;  // Return the arr elements
  }

  ignoreAuthRoute(url) {
    if (url.indexOf('login') == -1) {
      return true
    }
  }
}
