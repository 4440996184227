import {Component, ElementRef, OnInit, ViewChild, EventEmitter } from '@angular/core';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {MenuItems} from '../../shared/menu-items/menu-items';
import { AuthenticationService } from '../../services/authentication.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router } from '@angular/router';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import * as _ from 'lodash';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./additionalStyle.scss'],
  animations: [
    trigger('mobileMenuTop', [
      state(
        'no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state(
        'yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block => yes-block', [animate('200ms 10ms ease-in-out')]),
      transition('yes-block => no-block', [animate('50ms ease-in-out')]),
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(0, 0, 0)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(100%, 0, 0)',
        })
      ),
      transition('in => out', animate('200ms ease-in-out')),
      transition('out => in', animate('200ms ease-in-out')),
    ]),
    trigger('slideOnOff', [
      state(
        'on',
        style({
          transform: 'translate3d(0, 0, 0)',
        })
      ),
      state(
        'off',
        style({
          transform: 'translate3d(100%, 0, 0)',
        })
      ),
      transition('on => off', animate('200ms ease-in-out')),
      transition('off => on', animate('200ms ease-in-out')),
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('200ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AdminComponent implements OnInit {

  navType: string; /* st1, st2(default), st3, st4 */
  themeLayout: string; /* vertical(default) */
  layoutType: string; /* dark, light */
  verticalPlacement: string; /* left(default), right */
  verticalLayout: string; /* wide(default), box */
  deviceType: string; /* desktop(default), tablet, mobile */
  verticalNavType: string; /* expanded(default), offcanvas */
  verticalEffect: string; /* shrink(default), push, overlay */
  vNavigationView: string; /* view1(default) */
  pcodedHeaderPosition: string; /* fixed(default), relative*/
  pcodedSidebarPosition: string; /* fixed(default), absolute*/
  headerTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */
  logoTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */
  fullName: any;
  innerHeight: string;
  windowWidth: number;

  toggleOn: boolean;

  headerFixedMargin: string;
  navBarTheme: string; /* theme1, themelight1(default)*/
  activeItemTheme: string; /* theme1, theme2, theme3, theme4(default), ..., theme11, theme12 */

  isCollapsedMobile: string;
  isCollapsedSideBar: string;

  chatToggle: string;
  chatToggleInverse: string;
  chatInnerToggle: string;
  chatInnerToggleInverse: string;

  menuTitleTheme: string; /* theme1, theme2, theme3, theme4, theme5(default), theme6 */
  itemBorder: boolean;
  itemBorderStyle: string; /* none(default), solid, dotted, dashed */
  subItemBorder: boolean;
  subItemIcon: string; /* style1, style2, style3, style4, style5, style6(default) */
  dropDownIcon: string; /* style1(default), style2, style3 */
  configOpenRightBar: string;
  isSidebarChecked: boolean;
  isHeaderChecked: boolean;
  userName: {};
  role: any;
  @ViewChild('searchFriends', { static: false }) search_friends: ElementRef;
  /*  @ViewChild('toggleButton', {static:false}) toggle_button: ElementRef;
  @ViewChild('sideMenu', {static:false}) side_menu: ElementRef;*/

  optSlimScroll: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  ddShown = false;

  constructor(
    public menuItems: MenuItems,
    private authService: AuthenticationService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.navType = 'st2';
    this.themeLayout = 'vertical';
    this.vNavigationView = 'view1';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.deviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.pcodedHeaderPosition = 'fixed';
    this.pcodedSidebarPosition = 'fixed';
    this.headerTheme = 'theme1';
    this.logoTheme = 'theme1';
    // var test = localStorage.getItem('lsfullName').replace('"','');
    // var test1 = test.replace('"','');
    this.fullName = this.localStorageService.retrieve('fullname');
    // console.log(this.userName)
    this.toggleOn = true;

    this.headerFixedMargin = '80px';
    this.navBarTheme = 'themelight1';
    this.activeItemTheme = 'theme4';

    this.isCollapsedMobile = 'no-block';
    this.isCollapsedSideBar = 'no-block';

    this.chatToggle = 'out';
    this.chatToggleInverse = 'in';
    this.chatInnerToggle = 'off';
    this.chatInnerToggleInverse = 'on';

    this.menuTitleTheme = 'theme5';
    this.itemBorder = true;
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.subItemIcon = 'style6';
    this.dropDownIcon = 'style1';
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;

    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + 'px';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributes(this.windowWidth);
    this.role = this.localStorageService.retrieve('role');
  }

  ngOnInit() {
    this.setBackgroundPattern('pattern2');
    this.setSlimScroll();
  }

  setSlimScroll() {
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.optSlimScroll = {
      barWidth: '5',
      barOpacity: '0.3',
      position: 'right', // left | right
      barBackground: '#fff', // #C9C9C9
      barBorderRadius: '0', // 20
      barMargin: '0', // 0
      gridBackground: '#C9C9C9', // #D9D9D9
      gridOpacity: '0.5', // 1
      gridWidth: '2', // 2
      gridBorderRadius: '0', // 20
      gridMargin: '0', // 0
      alwaysVisible: false, // true
      visibleTimeout: 1000, // 1000
    };
  }

  onResize(event) {
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    let reSizeFlag = true;
    if (
      this.deviceType === 'tablet' &&
      this.windowWidth >= 768 &&
      this.windowWidth <= 1024
    ) {
      reSizeFlag = false;
    } else if (this.deviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'push';
      this.toggleOn = false;
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
      this.toggleOn = false;
    } else {
      this.deviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  toggleOpened() {

    if (this.windowWidth < 768 && this.toggleOn && this.verticalNavType === 'expanded') {
      this.verticalNavType = 'offcanvas';
      this.toggleOn = false;
      return false;
    }

    this.verticalNavType =
      this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';

    this.toggleOn = this.verticalNavType === 'expanded' ? true : false;
  }

  onClickedOutside(e: Event) {
    if (
      this.windowWidth < 768 &&
      this.verticalNavType === 'expanded'
    ) {
      this.verticalNavType = 'offcanvas';
      this.toggleOn = false;
    }
  }

  onMobileMenu() {
    this.isCollapsedMobile =
      this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }

  toggleChat() {
    this.chatToggle = this.chatToggle === 'out' ? 'in' : 'out';
    this.chatToggleInverse = this.chatToggleInverse === 'out' ? 'in' : 'out';
    this.chatInnerToggle = 'off';
    this.chatInnerToggleInverse = 'off';
  }

  toggleChatInner() {
    this.chatInnerToggle = this.chatInnerToggle === 'off' ? 'on' : 'off';
    this.chatInnerToggleInverse =
      this.chatInnerToggleInverse === 'off' ? 'on' : 'off';
  }

  searchFriendList(event) {
    const search = this.search_friends.nativeElement.value.toLowerCase();
    let search_input: string;
    let search_parent: any;
    const friendList = document.querySelectorAll(
      '.userlist-box .media-body .chat-header'
    );
    Array.prototype.forEach.call(friendList, function (elements, index) {
      search_input = elements.innerHTML.toLowerCase();
      search_parent = elements.parentNode.parentNode;
      if (search_input.indexOf(search) !== -1) {
        search_parent.classList.add('show');
        search_parent.classList.remove('hide');
      } else {
        search_parent.classList.add('hide');
        search_parent.classList.remove('show');
      }
    });
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar =
      this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
  }

  setSidebarPosition() {
    this.isSidebarChecked = !this.isSidebarChecked;
    this.pcodedSidebarPosition =
      this.isSidebarChecked === true ? 'fixed' : 'absolute';
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition =
      this.isHeaderChecked === true ? 'fixed' : 'relative';
    this.headerFixedMargin = this.isHeaderChecked === true ? '80px' : '';
  }

  setBackgroundPattern(pattern) {
    document.querySelector('body').setAttribute('themebg-pattern', pattern);
  }

  setLayoutType(type: string) {
    this.layoutType = type;
    if (type === 'dark') {
      this.headerTheme = 'theme6';
      this.navBarTheme = 'theme1';
      this.logoTheme = 'theme6';
      document.querySelector('body').classList.add('dark');
    } else {
      this.headerTheme = 'theme1';
      this.navBarTheme = 'themelight1';
      this.logoTheme = 'theme1';
      document.querySelector('body').classList.remove('dark');
    }
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
    } else {
      this.navBarTheme = 'theme1';
    }
  }

  logout() {
    const token = { accessToken: this.localStorageService.retrieve('token') };

    // this.localStorageService.set('token', data.result.accessToken);
    // this.localStorageService.set('fullName', data.result.fullName);
    this.localStorageService.clear('token');
    this.localStorageService.clear('fullName');

    // this.storage.clear('boundValue');
    // alert("Berhasil logout!");
    // this.router.navigate('/login');
    this.router.navigate(['/login']);
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }

  isAuthenticateRole(role) {
    return this.arrayMatch(role, this.role).length > 0 ? true : false;
  }

  arrayMatch(arr1, arr2) {
    let arr = []; // Array to contain match elements
    for (let i = 0; i < arr1.length; ++i) {
      for (let j = 0; j < arr2.length; ++j) {
        if (arr1[i] == arr2[j]) {
          // If element is in both the arrays
          arr.push(arr1[i]); // Push to arr array
        }
      }
    }

    return arr; // Return the arr elements
  }
}
