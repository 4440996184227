export enum Role {
    SUPERADMIN = "PRIVILEGE_ADMIN_ALL",
    CUSTOMER_SERVICE = "PRIVILEGE_CS",
    OPERATION = "PRIVILEGE_OPERATION"
}

export enum RoleName {
    PRIVILEGE_ADMIN_ALL = "Admin",
    PRIVILEGE_CS = "Customer Service",
    PRIVILEGE_OPERATION = "Operation"
}

export enum ProviderRoleName {
  PROVIDER_STUDIO = "Studio",
  PROVIDER_TRAINER = "Trainer",
  PROVIDER_COURT = "Court"
}