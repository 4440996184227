import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseService } from '../base/base-services/base.service';
import { Configuration } from '../base/base-services/configuration';
import { AuthService } from '../base/base-services/auth.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';


@Injectable()
export class CitiesService extends BaseService{

  constructor(http: HttpClient, auth: AuthService, localStorageService: LocalStorageService) {
    super(http, localStorageService);
    auth.isAuthenticated();
  }

  getCities() {
    return this.getData("../../assets/data/cities.json");
  }

}
