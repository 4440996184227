import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import {ToggleFullscreenDirective} from './fullscreen/toggle-fullscreen.directive';
import {AccordionAnchorDirective} from './accordion/accordionanchor.directive';
import {AccordionLinkDirective} from './accordion/accordionlink.directive';
import {AccordionDirective} from './accordion/accordion.directive';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MenuItems} from './menu-items/menu-items';
import {SpinnerComponent} from './spinner/spinner.component';
import {CardComponent} from './card/card.component';
import {CardRefreshDirective} from './card/card-refresh.directive';
import {CardToggleDirective} from './card/card-toggle.directive';
import {ModalAnimationComponent} from './modal-animation/modal-animation.component';
import {ModalBasicComponent} from './modal-basic/modal-basic.component';
import {DataFilterPipe} from './element/data-filter.pipe';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {NgxWebstorageModule, LocalStorageService} from 'ngx-webstorage';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { NgbdSortableHeaderDirective } from './directive/sortable.directive';
import { MatIconModule } from '@angular/material';
import { WidgetBannerTextComponent } from './widget-banner-text/widget-banner-text.component';
import { ToggleFilterComponent } from './toggle-filter/toggle-filter.component';
@NgModule({
  imports: [
    CommonModule,
    // ScrollModule,
    NgbModule,
    NgSlimScrollModule,
    MatIconModule
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    SpinnerComponent,
    CardComponent,
    ModalAnimationComponent,
    ModalBasicComponent,
    DataFilterPipe,
    NgbdSortableHeaderDirective,
    WidgetBannerTextComponent,
    ToggleFilterComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    // ScrollModule,
    NgSlimScrollModule,
    NgbModule,
    SpinnerComponent,
    CardComponent,
    ModalAnimationComponent,
    ModalBasicComponent,
    DataFilterPipe,
    FormsModule,
    ReactiveFormsModule,
    SnotifyModule,
    NgxWebstorageModule,
    NgbdSortableHeaderDirective,
    MatIconModule,
    WidgetBannerTextComponent,
    ToggleFilterComponent
  ],
  providers: [
    MenuItems,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService
  ]
})
export class SharedModule { }
