import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseService } from '../base/base-services/base.service';
import { Configuration } from '../base/base-services/configuration';
import { AuthService } from '../base/base-services/auth.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ResponseNewStrongbeeModel } from '@model/response-strongbee.model';
import { map } from 'rxjs/operators';
@Injectable()
export class EventService extends BaseService {

  constructor(http: HttpClient, auth: AuthService, localStorageService: LocalStorageService,
    private session:SessionStorageService) {
    super(http, localStorageService);
    auth.isAuthenticated();
  }

  getListEvent(page:number, limit:number, active:boolean, name?:string, category?:string, expertise?:string, location?:string, sortBy?: string, direction?: string) {
      let request = {"page": page, "limit": limit, "name": name, 
        "category": category,"expertise": expertise, "location": location, 
        "active": active, "sort-by": sortBy, "direction": direction}
      
      return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_EVENT, this.removeUndefinedParams(request));
  }

  getListRequestEvents(page:number, limit:number, name?:string, request_start_date?:string, request_end_date?:string, establishment_name?: string, event_start_date?: string, event_end_date?: string, sortBy?: string, direction?: string, request_status?: string) {
    let request = {"page": page, "limit": limit, "name": name, 
      "request_date_start": request_start_date, "request_date_end": request_end_date, "establishment_id": establishment_name, 
      "event_date_start": event_start_date, "event_date_end": event_end_date, "sort-by": sortBy, "direction": direction, "request_status": request_status}
    
    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_REQUEST_EVENTS, this.removeUndefinedParams(request));
  }

  rejectRequestedEvent(data){
    const request = {'id': data.id, 'request_event_status': data.status};

    return this.updateDataNoIDWithHeader<ResponseNewStrongbeeModel>(
      `${Configuration.BASE_URL}${Configuration.REJECT_REQUESTED_EVENT}`, request);
  }

  acceptRequestedEvent(data){
    const request = {'event_id': data.event_id, 'partner_type': data.partner_type, 'partner_payment_period': data.partner_payment_period, 'bank_name': data.bank_name, 'bank_account_number': data.bank_account_number, 'partner_commission_type': data.partner_commission_type, 'commission_percentage': data.commission_percentage, 'bank_account_name': data.bank_account_name, 'bank_fee': data.bank_fee, 'commission_amount': data.commission_amount};

    return this.createDataHeaderNoParams<ResponseNewStrongbeeModel>(Configuration.BASE_URL + Configuration.ACCEPT_REQUESTED_EVENT, request);
  }

  getRequestedEventDetails(id) {
    const request = {
      "event-id": id
    };

    return this.getDataHeader<ResponseNewStrongbeeModel>(
      Configuration.BASE_URL + Configuration.REQUESTED_EVENT_DETAILS, this.removeUndefinedParams(request),
    ).pipe(map(post => {

    return post.result;
  }));
  }

  getListEventDirectories(){
    return this.getData(Configuration.BASE_URL + Configuration.LIST_EVENT_DIRECTORIES);
  }

  getListEventExpertises(){
    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_EVENT_EXPERTISES, {"limit": 100});
  }

  getListCurrencies() {
    return this.getData(Configuration.BASE_URL + Configuration.LIST_CURRENCIES);
  }

  getPaginate(data1, data2, data3) {
    let token = this.getToken();
    let request = { "access_token": token, "page": data1, "limit": data2, "search": data3 }
    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_BOOKING, request);
  }

  getDetailEvent(id) {
    let token = this.getToken();
    let request = {"id": id}
    return this.getDataParam(Configuration.BASE_URL + Configuration.DETAIL_EVENT, request);
  }
  createEvent(data){
    let token = this.getToken();
    let request = {"access_token": token}
    return this.createDataParam(Configuration.BASE_URL + Configuration.CREATE_EVENT, data, request)
  }

  editEvent(data){
    let token = this.getToken();
    let request = {"access_token": token}
    return this.updateDataParam(Configuration.BASE_URL + Configuration.UPDATE_EVENT, data, data.id, request);
  }

  // getDetailEvent(id){
  //   let request = {"id": id}
  //   return this.getDataParam(Configuration.BASE_URL + Configuration.DETAIL_EVENT,request);
  // }

  getListTags(searchData){
    let request = {'searchBy': 'name', 'searchVal': searchData}
    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_TAG, request)
  }

  getListEstablishment(searchData){
    let request = { "searchBy": "name", "searchVal": searchData}
    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_ESTABLISHMENT_FILTER, request)
  }

  getEstablishmentDetail(id) {
    let token = this.getToken();
    let request = { "access_token": token,"id":id }
    return this.getDataParam(Configuration.BASE_URL + Configuration.DETAIL_ESTABLISHMENT, request);
  }
}
