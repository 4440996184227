import {Routes, CanActivate} from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import {AuthGuardService as AuthGuard } from './base/base-services/auth-guard.service';

export const AppRoutes: Routes = [ {
    path: '',
    component: AuthComponent,
    children: [
      // {
      //   path: 'authentication',
      //   loadChildren: './pages/authentication/authentication.module#AuthenticationModule'
      // },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren: () => import('./pages/maintenance/offline-ui/offline-ui.module').then(m => m.OfflineUiModule)
      }, {
        path: 'login',
        loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule)
      }, {
        path: 'cms/reset-password',
        loadChildren: () => import('./pages/authentication/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
      }, {
        path: 'cms/activate-user',
        loadChildren: () => import('./pages/authentication/activation-account/activation-account.module').then(m => m.ActivationAccountModule),
      }, {
        path: 'forgot-password',
        loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/authentication/change-password/change-password.module').then(m => m.ChangePasswordModule),
        canActivate: [AuthGuard]
      },
  
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/beranda/beranda.module').then(m => m.BerandaModule),
        canActivate : [AuthGuard]
      },
      {
        path: 'report-booking-summary',
        loadChildren: () => import('./pages/report-booking-summary/report-booking-summary.module').then(m => m.ReportBookingSummaryModule),
        canActivate : [AuthGuard]
      },
      {
        path: 'role',
        loadChildren: () => import('./pages/trainer/role.module').then(m => m.RoleModule),
        canActivate : [AuthGuard]
      },
      {
        path: 'rating-review',
        loadChildren: () => import('./pages/rating-review/rating-review.module').then(m => m.RatingReviewModule),
        canActivate : [AuthGuard]
      },
      {
        path: 'schedule',
        canActivate : [AuthGuard],
        children: [
          {
            path: 'class',
            loadChildren: () => import('./pages/schedule-class/schedule-class.module').then(m => m.ScheduleClassModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'trainer',
            loadChildren: () => import('./pages/schedule-trainer/schedule-trainer.module').then(m => m.ScheduleTrainerModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'favourite-schedule',
            loadChildren: () => import('./pages/favourite-schedule/favourite-schedule.module').then(m => m.FavouriteScheduleModule),
            canActivate: [AuthGuard]
          },
        ]
      },
      {
        path: 'booking',
        canActivate : [AuthGuard],
        children: [
          {
            path: 'class',
            loadChildren: () => import('./pages/booking-class/booking-class.module').then(m => m.BookingClassModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'offline-class',
            loadChildren: () => import('./pages/booking-offline-class/booking-offline-class.module').then(m => m.BookingClassModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'trainer',
            loadChildren: () => import('./pages/booking/booking.module').then(m => m.BookingModule),
            canActivate : [AuthGuard],
          },
          {
            path: 'court',
            loadChildren: () => import('./pages/booking-court/booking-court.module').then(m => m.BookingCourtModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'event',
            loadChildren: () => import('./pages/booking-event/booking-event.module').then(m => m.BookingEventModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'bpass',
            loadChildren: () => import('./pages/booking-bpass/booking-bpass.module').then(m => m.BookingBpassModule),
            canActivate: [AuthGuard]
          },
        ]
      },
      {
        path: 'service',
        canActivate : [AuthGuard],
        children: [
          {
            path: 'classes',
            loadChildren: () => import('./pages/classes/classes.module').then(m => m.ClassesModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'establishment',
            loadChildren: () => import('./pages/establish/content.module').then(m => m.ContentModule),
            canActivate : [AuthGuard]
          },
          {
            path: 'professionals',
            loadChildren: () => import('./pages/trainer/trainer.module').then(m => m.TrainerModule),
            canActivate : [AuthGuard]
          },
          {
            path: 'event',
            loadChildren: () => import('./pages/event/event.module').then(m => m.EventModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'request-events',
            loadChildren: () => import('./pages/request-events/request-events.module').then(m => m.RequestEventsModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'bpass',
            loadChildren: () => import('./pages/bpass/bpass.module').then(m => m.BpassModule),
            canActivate: [AuthGuard]
          },
        ]
      },
      {
        path: 'zoom',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'class',
            loadChildren: () => import('./pages/zoom-class/zoom-class.module').then(m => m.ZoomClassModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'professional',
            loadChildren: () => import('./pages/zoom-professional/zoom-professional.module').then(m => m.ZoomProfessionalModule),
            canActivate: [AuthGuard]
          },
        ]
      },
      {
        path: 'voucher',
        canActivate : [AuthGuard],
        children: [
          {
            path: 'promocode',
            loadChildren: () => import('./pages/promocode/promocode.module').then(m => m.PromocodeModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'cashback',
            loadChildren: () => import('./pages/cashback/cashback.module').then(m => m.CashbackModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'strongwheel-reward',
            loadChildren: () => import('./pages/strongwheel/strongwheel.module').then(m => m.StrongwheelModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'referal',
            loadChildren: () => import('./pages/referal/referal.module').then(m => m.ReferalModule),
            canActivate : [AuthGuard],
          },
        ]
      },
      // {
      //   path: 'referal',
      //   loadChildren: () => import('./pages/referal/referal.module').then(m => m.ReferalModule),
      //   canActivate : [AuthGuard],
      // },
      {
        path: 'speciality',
        loadChildren: () => import('./pages/speciality/speciality.module').then(m => m.SpecialityModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'apps',
        canActivate : [AuthGuard],
        children: [
          {
            path: 'homecard',
            loadChildren: () => import('./pages/homecard/homecard.module').then(m => m.HomecardModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'quotes',
            loadChildren: () => import('./pages/quotes/quotes.module').then(m => m.QuotesModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'question',
            loadChildren: () => import('./pages/question/question.module').then(m => m.QuestionModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'provider-banner',
            loadChildren: () => import('./pages/applications/provider-banner/provider-banner.module').then(m => m.ProviderBannerModule),
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'payment-partner',
        canActivate : [AuthGuard],
        children: [
          {
            path: 'establishment',
            canActivate : [AuthGuard],
            loadChildren: () => import('./pages/payment-partner/establishment/establishment.module').then(m => m.EstablishmentModule),
          },
          {
            path: 'trainer',
            canActivate : [AuthGuard],
            loadChildren: () => import('./pages/payment-partner/trainer/trainer.module').then(m => m.TrainerModule),
          },
          {
            path: 'event',
            canActivate : [AuthGuard],
            loadChildren: () => import('./pages/payment-partner/event/event.module').then(m => m.EventModule),
          },
          {
            path: 'accept-reject',
            canActivate : [AuthGuard],
            //loadChildren: () => import('./pages/payment-partner/accept-reject/accept-reject.module').then(m => m.AcceptRejectModule),
            children : [
              {
                path: 'list',
                canActivate : [AuthGuard],
                loadChildren: () => import('./pages/payment-partner/accept-reject/accept-reject-list/accept-reject-list.module').then(m => m.AcceptRejectListModule),
              },
            ]
          },
          {
            path: 'setting',
            canActivate : [AuthGuard],
           // loadChildren: () => import('./pages/payment-partner/setting-payment/setting-payment.module').then(m => m.SettingPaymentModule),
            children : [
              {
                path: 'list',
                canActivate : [AuthGuard],
                loadChildren: () => import('./pages/payment-partner/setting-payment/setting-payment.module').then(m => m.SettingPaymentModule),
              },
              {
                path: 'edit/:id',
                canActivate : [AuthGuard],
                loadChildren: () => import('./pages/payment-partner/setting-payment/edit-setting-payment/edit-setting-payment.module').then(m => m.EditSettingPaymentModule),
              },
            ]
          },
        ]
      },
      {
        path: 'categories',
        canActivate : [AuthGuard],
        children: [
          {
            path: 'category',
            loadChildren: () => import('./pages/category/category.module').then(m => m.HomecardModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'subcategory',
            loadChildren: () => import('./pages/subcategory/subcategory.module').then(m => m.SubcategoryModule),
            canActivate: [AuthGuard]
          },
        ]
      },
      {
        path: 'account',
        canActivate : [AuthGuard],
        children: [
          // {
          //   path: 'users',
          //   loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
          //   canActivate : [AuthGuard]
          // },
          {
            path: 'users',
            loadChildren: () => import('./pages/account/users/users.module').then(m => m.UsersModule),
            canActivate : [AuthGuard]
          },
          {
            path: 'trainer-account',
            loadChildren: () => import('./pages/trainer-account/trainer-account.module').then(m => m.TrainerAccountModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'admin',
            loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'provider',
            loadChildren: () => import('./pages/account/provider/provider.module').then(m => m.ProviderModule),
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'expertise',
        loadChildren: () => import('./pages/expertise/expertise.module').then(m => m.ExpertiseModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tags',
        canActivate : [AuthGuard],
        children: [
          {
            path: 'tag',
            loadChildren: () => import('./pages/tag/tag.module').then(m => m.TagModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'tagservice',
            loadChildren: () => import('./pages/tagservices/tagservices.module').then(m => m.TagservicesModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'tagservicefilter',
            loadChildren: () => import('./pages/tagservice-filter/tagservice-filter.module').then(m => m.TagserviceFilterModule),
            canActivate: [AuthGuard]
          },
        ]
      },
      {
        path: 'promocode',
        loadChildren: () => import('./pages/promocode/promocode.module').then(m => m.PromocodeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'pushmessage',
        loadChildren: () => import('./pages/pushmessage/pushmessage.module').then(m => m.PushmessageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'penalty-config',
        loadChildren: () => import('./pages/penalty-config/penalty-config.module').then(m => m.PenaltyConfigModule),
        canActivate: [AuthGuard]
      },
    ]
  }
];
