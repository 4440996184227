import { Expertise, TrainerModel, WOR,
     TrainerClass, TrainerPhotoHeader, TrLocation, TrLocationSchedule, TrLocationScheduleShift, TrainerScheduleList } from '../trainer.model';
import {required, pattern, propArray, prop, propObject, maxLength, minNumber} from '@rxweb/reactive-form-validators';
import { RegexPattern } from '../../base/base-component/regex-pattern';
import { Tag } from '../tag.model';
import * as Yup from 'yup';
import { min } from 'rxjs/operators';

const getMsgReq = (name) => {
    return `${name} is Mandatory`;
};
const reqYupMsg = (fe: Yup.TestMessageParams) => `${fe.label ? fe.label : fe.path} is Required`;
class RxClasses implements TrainerClass {

    @required({message: getMsgReq('Class Name')})
    class_name: string;

    @required({message: getMsgReq('Fee')})
    fee: number;

    @required({message: getMsgReq('Flag Price')})
    flag_price: string;

    @prop({defaultValue: 1})
    @required({message: getMsgReq('Min. Participants')})
    minimum_participants: number;

    @prop({defaultValue: 1})
    maximum_participants: number;

    @prop({'defaultValue': {
        'schedule': [],
        'location_address': '',
    }})
    location: RxTrLocation[];
}
class RxTrLocation implements TrLocation {
    @required({message: getMsgReq('Schedule')})
    schedule: RxTrLocationSchedule[];
 
}
class RxTrLocationSchedule implements TrLocationSchedule {
    @required({message: getMsgReq('Shift Classes')})
    shift: TrLocationScheduleShift[];
}
class RxPriceExpertise implements Expertise {
    @required({message: getMsgReq('Start From field')})
    range_price: number;

    @required({message: getMsgReq('Strongbee Price field')})
    strongbee_price: number;

    @required({message: getMsgReq('Maximum Participants')})
    maximum_participants: number;
}
class RxWOR implements WOR {}

export class TrainerRxForm implements TrainerModel {

    @prop()
    @required({'message': getMsgReq('name')})
    name: string;

    @prop()
    @required({'message': getMsgReq('gender')})
    gender: string;

    @prop()
    @required({'message': getMsgReq('gender')})
    dob: string;

    @required({'message': getMsgReq('bio')})
    @maxLength({value: 700, 'message': 'Bio description is reached maximum text'})
    bio: string;

    @required({'message': getMsgReq('Phone Number')})
    phone_number: number;

    @prop()
    @required({'message': getMsgReq('Email')})
    @pattern({'expression': {'email': RegexPattern.EMAIL}, message: 'Email is Not matched'})
    email: string;

    @prop()
    @required({'message': getMsgReq('Year of Experiences')})
    experiences: number;

    @prop()
    @required({'message': getMsgReq('Profile Picture')})
    photo_url: string;

    @prop()
    instagram: string;

    @prop({defaultValue: false})
    certified: boolean;

    @prop({defaultValue: false})
    show_number: boolean;

    @prop({defaultValue: false})
    is_professional: boolean;

    @prop({defaultValue: false})
    is_booking: boolean;

    @prop({defaultValue: false})
    house_visit: boolean;

    @prop({defaultValue: false})
    group_session: boolean;

    @propArray(RxClasses)
    classes: RxClasses[];

    @prop()
    @required({message: getMsgReq('Address')})
    address: string;

    @propObject(RxPriceExpertise)
    expertise: RxPriceExpertise;

}

export const TrainerYupFormValidate = Yup.object().shape<TrainerModel>({
  'name': Yup.string().required(reqYupMsg),
  'address': Yup.string().required(reqYupMsg),
  'gender': Yup.string().required(reqYupMsg),
  'tags': Yup.array().typeError(reqYupMsg).required(reqYupMsg).label('Tags'),
  'email': Yup.string().required(reqYupMsg).matches(RegexPattern.EMAIL, 'Email not match'),
  'phone_number': Yup.string().required(reqYupMsg).matches(RegexPattern.NEW_PHONE_INT, 'Phone not match').label('Phone Number'),
  'expertises': Yup.array().required(reqYupMsg).label('Expertise'),
  'bio': Yup.string().required(reqYupMsg).max(700, 'Maximum character is 700'),
  'experiences': Yup.number().typeError(reqYupMsg).required(reqYupMsg).label('Experiences'),
  'photo_url': Yup.string().required(reqYupMsg).label('Photo Profile'),
  'professional_photo_header': Yup.array().required(reqYupMsg).label('Photo Header'),
  'working_areas': Yup.array().required(reqYupMsg).label('Working Area'),
  'expertise': Yup.object<Expertise>({
    'strongbee_price': Yup.number().typeError(reqYupMsg).required(reqYupMsg).min(0, '${label} minimum from 0').label('Strongbee Price'),
    'range_price': Yup.number().typeError(reqYupMsg).required(reqYupMsg).min(0, '${label} minimum from 0').label('Range Price'),
    'maximum_participants': Yup.number().typeError(reqYupMsg).required(reqYupMsg).min(1, '${label} value from 1').label('Max Participant')
  }),

  'schedule_period_type': 
          Yup.string()
          .when(['is_booking'], {
            is: (is_booking: boolean) => { 
              if(is_booking == true) {
                return true
              }
            },
            then: Yup.string().required('Schedule period type cannot be blank')
          }),

  'classes': Yup.array().of(Yup.object<TrainerClass>({
    'class_name': Yup.string().required(reqYupMsg).label('Class name in Classes'),
    'fee': Yup.number().typeError(reqYupMsg)
      .required(reqYupMsg)
      .min(0, fe => `${fe.label} minimum from 0`).label(`Price in classes`),
    'flag_price': Yup.string().required(reqYupMsg).label(`Flag price in classes`),
    'minimum_participants': Yup.number().typeError(reqYupMsg).required(reqYupMsg)
      .min(1, fe => `${fe.label} value from 1`).label('Min. Participants in classes'),
    'maximum_participants': Yup.number().typeError(reqYupMsg).required(reqYupMsg)
      .min(Yup.ref('minimum_participants'), fe => `${fe.label} value from ${fe.min}`)
      .label('Max. Participant in classes'),
    // 'description': Yup.string().required(reqYupMsg).max(700, 'Maximum character is 700').label('Description in classes'),
    // 'duration': Yup.number().typeError(reqYupMsg)
    //   .required(reqYupMsg)
    //   .moreThan(0)
    //   .label('Duration in classes'),
    // 'tags': Yup.array().typeError(reqYupMsg).required(reqYupMsg).label('Tags in classes')
  })),
  'schedule_list': Yup.array().of(Yup.object<TrainerScheduleList>({
    'shift': Yup.array().required(reqYupMsg).label('Shift Time in Trainer Schedule')
  })).required(reqYupMsg).label('Trainer Schedule'),
  'dob': Yup.string().required(reqYupMsg).label('Date of Birth'),
  'start_year_experiences': Yup.number().typeError(reqYupMsg).required(reqYupMsg)
    .min(1900, 'value in Experience Since field must after 1900').label('Experience Since '),
  'professional_videos': 
    Yup.array()
      .of(
        Yup.object().shape(
          {
            'field_sequence_number': 
                Yup.number()
                    .typeError('Valid field sequence number is required')
                    .required('Field sequence number is required')
                    .moreThan(0, 'Valid field sequence number is required'),
            'video_url':
               Yup.string().required('Video url is required'),
            'video_cover_image_url':
              Yup.string().required('Video cover image is required')
          }
        )
    ),
});

// export const TrainerClassYupFormValidate = Yup.object().shape<TrainerClass>({});
