import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle-filter',
  templateUrl: './toggle-filter.component.html',
  styleUrls: ['./toggle-filter.component.css'],
  animations: [
    trigger('toggleTrigger', [
      state('off', style({ height: '0' })),
      state('on', style({ height: '*' })),
      transition('off <=> on', [animate('120ms ease-in')]),
      transition('on <=> off', [animate('120ms ease-out')])
    ])
  ]
})
export class ToggleFilterComponent implements OnInit {
  @Input() toggleOn: boolean;
  @Output() toggledTo = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log('toggleFilter init toggleOn ', this.toggleOn);
  }

  toggleClick() {
    if (this.toggleOn) {
      this.toggleOn = false;
      this.toggledTo.emit('off');
    } else {
      this.toggleOn = true;
      this.toggledTo.emit('on');
    }

    console.log('toggleFilter toggleClick toggleOn', this.toggleOn);
  }

}
