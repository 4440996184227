import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseService } from '../base/base-services/base.service';
import { Configuration } from '../base/base-services/configuration';
import { AuthService } from '../base/base-services/auth.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable, forkJoin } from 'rxjs';
import { ResponseStrongbeeModel } from '../model/response-strongbee.model';




@Injectable()
export class UploadImageService extends BaseService{

  constructor(http: HttpClient, auth: AuthService, localStorageService: LocalStorageService) { 
    super(http, localStorageService);
    auth.isAuthenticated();
  }

  addFd(data) {
    let fd = new FormData();
    fd.append('file', data);
    fd.append('type', 'customer');
    return fd;
  }

  uploadMultipleImgs(data1, data2): Observable<any[]> {
    let token = this.getToken();
    let request = { "access_token": token };
    let fd1 = this.addFd(data1);
    let fd2 = this.addFd(data2);
    let response1 = this.createDataParam(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO, fd1, request)
    let response2 = this.createDataParam(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO, fd2, request)
    return forkJoin([response1, response2]);
  }

  uploadMultiImageType(type, ...listImage){
    
    let listObservable = []

    for (let img of listImage) {
      let fd = new FormData();
      let token = this.getToken()
      let request = { "access_token": token}
      
      fd.append('file', img);
      fd.append('type', type);
      
      listObservable.push(this.createDataParam(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO, fd, request))
    }
    console.log(listObservable);
    return forkJoin(listObservable)
  }

  uploadImg(data) {
    let token = this.getToken();
    let request = { "access_token": token };
    let fd = this.addFd(data);
    return this.createDataParam(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO, fd, request);
  }

  uploadImage(data) {
    let token = this.getToken();
    let request = { "access_token": token }
    return this.createDataParam(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO, data, request)
  }

  uploadImageNoToken(data) {
  
    return this.createDataHeaderNoParams(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO, data)
  }

 

  uploadImgFormData(image, type='customer'){
    let fd = new FormData();
    let token = this.getToken();
    let request = { "access_token": token}

    fd.append('file', image);
    fd.append('type', type);

    return this.createDataParam<ResponseStrongbeeModel<string>>(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO,fd,request)
  }

}
