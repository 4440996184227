import { state } from '@angular/animations';
import {Injectable} from '@angular/core';
import { Role } from '../../enum/role.enum';
import {LocalStorageService} from 'ngx-webstorage';
import { AuthService } from '@base/base-services/auth.service';
import { environment } from 'environments/environment';
import { Injector } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const injector = Injector.create({providers: [{provide: AuthService, deps:[]}]});

const authService = injector.get(AuthService)

const bearerToken = authService.getToken
const username = authService.getUsername
const chatUrl = environment.chat_url

const MENUITEMS = [

  {
    label: 'Admin',
    authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION],
    main: [
      {
        state: 'dashboard',
        sub_state: null,
        short_label: 'F',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home',
        authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
      },
      {
        state: 'booking',
        name: 'Booking',
        type: 'sub',
        icon: 'ti-agenda',
        authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION],
        children: [
          {
            state: 'trainer',
            sub_state: 'list',
            name: 'Booking Trainers',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'class',
            sub_state: 'list',
            name: 'Booking Classes',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'offline-class',
            sub_state: 'list',
            name: 'Walk-in Booking',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'court',
            sub_state: 'list',
            name: 'Booking Courts',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'event',
            sub_state: 'list',
            name: 'Booking Events',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'bpass',
            sub_state: 'list',
            name: 'Booking BPass',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
        ]
      },
      {
        state: 'service',
        name: 'Product',
        type: 'sub',
        icon: 'ti-support',
        authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION],
        children: [
          {
            state: 'professionals',
            sub_state: 'list',
            name: 'Professionals',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'classes',
            sub_state: 'list',
            name: 'Classes',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'establishment',
            sub_state: 'list',
            name: 'Establishment',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'event',
            sub_state: 'list',
            name: 'Events',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'request-events',
            sub_state: 'list',
            name: 'Requested Event',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          },
          {
            state: 'bpass',
            sub_state: 'list',
            name: 'BPass',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION]
          }
        ]
      },
      {
        state: 'schedule',
        name: 'Set Schedule',
        type: 'sub',
        icon: 'ti-calendar',
        authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE, Role.OPERATION],
        children: [
          {
            state: 'class',
            sub_state: 'view',
            name: 'Set Establishment Schedule',
            authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE]
          },
          {
            state: 'trainer',
            sub_state: 'view',
            name: 'Set Trainer Schedule',
            authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE], 
          },
          {
            state: 'favourite-schedule',
            sub_state: 'list',
            name: 'Favourite Schedule',
            authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE]
          },
        ]
      },
      {
        state: 'chat',
        name: 'Chat',
        sub_state: 'list',
        short_label: 'F',
        type: 'external',
        target: true,
        external:`${chatUrl}?token=${bearerToken}&username${username}`,
        icon: 'ti-email',
        authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
      },
      {
        state: 'zoom',
        name: 'Zoom',
        type: 'sub',
        icon: 'ti-layout-media-overlay-alt',
        authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE],
        children: [
          {
            state: 'class',
            sub_state: 'list',
            name: 'Zoom Class',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE]
          },
          {
            state: 'professional',
            sub_state: 'list',
            name: 'Zoom Professional',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE]
          },
        ]
      },
      {
        state: 'voucher',
        name: 'Voucher',
        type: 'sub',
        icon: 'ti-credit-card',
        authorization: [Role.SUPERADMIN],
        children: [
          {
            state: 'promocode',
            sub_state: 'list',
            short_label: 'F',
            name: 'Promo Code',
            authorization: [Role.SUPERADMIN],
          },
          {
            state: 'cashback',
            sub_state: 'list',
            short_label: 'F',
            name: 'Cashback',
            authorization: [Role.SUPERADMIN],
          },
          {
            state: 'strongwheel-reward',
            sub_state: 'list',
            short_label: 'F',
            name: 'Strongwheel Rewards',
            authorization: [Role.SUPERADMIN],
          },
          {
            state: 'referal',
            name: 'Referral',
            type: 'sub',
            authorization: [Role.SUPERADMIN],
            children: [
              {
                state: 'setting',
                short_label: 'F',
                name: 'Setting Referral',
                authorization: [Role.SUPERADMIN],
              },
              {
                state: 'list-code',
                short_label: 'F',
                name: 'List Referral Code',
                authorization: [Role.SUPERADMIN],
              },
              {
                state: 'joined-users',
                short_label: 'F',
                name: 'List Joined Users',
                authorization: [Role.SUPERADMIN],
              },
            ]
          },
        ]
      },
      {
        state: 'report-booking-summary',
        name: 'Report',
        sub_state: 'list',
        short_label: 'F',
        type: 'link',
        icon: 'ti-bag',
        authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
      },
      {
        state: 'pushmessage',
        sub_state: 'list',
        short_label: 'F',
        name: 'Push Message',
        type: 'link',
        icon: 'ti-rss-alt',
        authorization: [Role.SUPERADMIN, Role.OPERATION],
      },
      {
        state: 'apps',
        name: 'Applications',
        type: 'sub',
        icon: 'ti-tablet',
        authorization: [Role.SUPERADMIN, Role.OPERATION],
        children: [
          {
            state: 'homecard',
            sub_state: 'list',
            name: 'Homepage Card',
            authorization: [Role.SUPERADMIN, Role.OPERATION],
          },
          {
            state: 'quotes',
            sub_state: 'list',
            name: 'Quotes',
            authorization: [Role.SUPERADMIN, Role.OPERATION],
          },
          {
            state: 'question',
            sub_state: 'list',
            name: 'Questionnare',
            authorization: [Role.SUPERADMIN, Role.OPERATION],
          },
          {
            state: 'provider-banner',
            sub_state: 'listing',
            name: 'Provider Banner',
            authorization: [Role.SUPERADMIN, Role.OPERATION]
          }
        ]
      },
      {
        state: 'payment-partner',
        name: 'Payment Partner',
        type: 'sub',
        icon: 'ti-money',
        authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
        children: [
          {
            state: 'establishment',
            name: 'Establishment',
            type: 'sub',
            authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
            children: [
              {
                state: 'transactions',
                name: 'Transactions',
                authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
              },
              {
                state: 'pending-withdraw',
                name: 'Pending Withdraw',
                authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
              },
            ]
          },
          {
            state: 'trainer',
            name: 'Trainer',
            type: 'sub',
            authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
            children: [
              {
                state: 'transactions',
                name: 'Transactions',
                authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
              },
            ]
          },
          {
            state: 'event',
            name: 'Event',
            type: 'sub',
            authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
            children: [
              {
                state: 'transactions',
                name: 'Transactions',
                authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
              },
            ]
          },
          {
            state: 'setting',
            sub_state: 'list',
            name: 'Setting',
            authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
          },
          {
            state: 'accept-reject',
            sub_state: 'list',
            name: 'Request Bank',
            authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
          }
        ]
      },
      {
        state: 'penalty-config',
        name: 'Penalty Config',
        sub_state: 'list',
        short_label: 'F',
        type: 'link',
        icon: 'ti-flag',
        authorization: [Role.SUPERADMIN, Role.OPERATION, Role.CUSTOMER_SERVICE],
      },
      {
        state: 'account',
        name: 'Account Management',
        type: 'sub',
        icon: 'ti-user',
        authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE],
        children: [
          {
            state: 'provider',
            sub_state: 'list',
            short_label: 'F',
            name: 'Provider',
            authorization: [Role.SUPERADMIN, Role.OPERATION],
          },
          {
            state: 'users',
            sub_state: 'list',
            short_label: 'F',
            name: 'Users',
            authorization: [Role.SUPERADMIN, Role.CUSTOMER_SERVICE],
          },
          {
            state: 'trainer-account',
            sub_state: 'list',
            short_label: 'F',
            name: 'Trainer Account',
            authorization: [Role.SUPERADMIN],
          },
          {
            state: 'admin',
            sub_state: 'list',
            short_label: 'F',
            name: 'Admin & Roles',
            authorization: [Role.SUPERADMIN],
          }
        ]
      },
      {
        state: 'rating-review',
        sub_state: 'list',
        short_label: 'F',
        name: ' Rating & Reviews',
        type: 'link',
        icon: 'ti-star',
        authorization: [Role.SUPERADMIN, Role.OPERATION],
      },
      {
        state: 'expertise',
        sub_state: 'list',
        short_label: 'F',
        name: 'Expertise',
        type: 'link',
        icon: 'ti-vector',
        authorization: [Role.SUPERADMIN],
      },
      {
        state: 'tags',
        name: 'Tag',
        icon: 'ti-tag',
        type: 'sub',
        authorization: [Role.SUPERADMIN, Role.OPERATION],
        children: [
          {
            state: 'tag',
            sub_state: 'list',
            short_label: 'F',
            name: 'Card Tag',
            authorization: [Role.SUPERADMIN, Role.OPERATION],
          },
          {
            state: 'tagservice',
            sub_state: 'list',
            short_label: 'F',
            name: 'Tag Services',
            authorization: [Role.SUPERADMIN, Role.OPERATION],
          },
          {
            state: 'tagservicefilter',
            sub_state: 'list',
            short_label: 'F',
            name: 'Tag Service Filter',
            authorization: [Role.SUPERADMIN, Role.OPERATION],
          },
        ]
      },
      // {
      //   state: 'categories',
      //   name: 'Category',
      //   icon: 'ti-notepad',
      //   type: 'sub',
      //   authorization: [Role.SUPERADMIN, Role.OPERATION],
      //   children: [
      //     {
      //       state: 'category',
      //       sub_state: 'list',
      //       short_label: 'F',
      //       name: 'List Category',
      //       authorization: [Role.SUPERADMIN, Role.OPERATION],
      //     },
      //     {
      //       state: 'subcategory',
      //       sub_state: 'list',
      //       short_label: 'F',
      //       name: 'Subcategory',
      //       authorization: [Role.SUPERADMIN, Role.OPERATION],
      //     }
      //   ]
      // }
    ]
  }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
