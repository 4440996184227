import { ResponseStrongbeeModel } from './../model/response-strongbee.model';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseService } from '../base/base-services/base.service';
import { Configuration } from '../base/base-services/configuration';
import { AuthService } from '../base/base-services/auth.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { map } from 'rxjs/operators';
import { BookingTrainerWebEntity } from '../model/booking-trainer.model';

@Injectable()
export class BookingService extends BaseService {

  constructor(http: HttpClient, auth: AuthService, localStorageService: LocalStorageService) {
    super(http, localStorageService);
    auth.isAuthenticated();
  }

  getPaginate(data1, data2, data3) {
    const token = this.getToken();
    const request = { 'access_token': token, 'page': data1, 'limit': data2, 'search': data3 };
    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_BOOKING, request);
  }

  getDetailBooking(code) {
    const request = { 'code': code };
    return this.getDataHeader<ResponseStrongbeeModel<BookingTrainerWebEntity>>(
      Configuration.BASE_URL + Configuration.DETAIL_BOOKING, request);
  }

  editBooking(data) {
    return this.updateDataNoIDWithHeader(Configuration.BASE_URL + Configuration.EDIT_BOOKING, data);
  }

  deleteBooking(id) {
    const token = this.getToken();
    const request = { 'access_token': token, 'id': id };
    return this.deleteDataParam(Configuration.BASE_URL + Configuration.DELETE_BOOKING, request);
  }

  createBooking(data) {
    const token = this.getToken();
    const request = { 'access_token': token };
    return this.createDataParam(Configuration.BASE_URL + Configuration.CREATE_BOOKING, data, request);
  }

  getListForBooking(page, limit, day, speciality, gender, price_min, price_max, time_start, time_end, date) {
    const token = this.getToken();
    const request = { 'access_token': token, 'page': page, 'limit': limit, 'day': day, 'speciality': speciality, 'gender': gender, 'price_min': price_min, 'price_max': price_max, 'time_start': time_start, 'time_end': time_end, 'book_date': date };
    return this.getDataParam(Configuration.BASE_URL + Configuration.GET_ADVANCED_LIST, request);
  }

  getBookingParticipantList(code: string) {
    const request = { code };
    return this.getDataHeader(Configuration.BASE_URL + Configuration.GET_BOOKING_TRAINER_PARTICIPANT, request);
  }

  updateBookingParticipantList(data) {
    return this.createDataHeaderNoParams(Configuration.BASE_URL + Configuration.UPDATE_BOOKING_TRAINER_PARTICIPANT, data);
  }

  createVerifikasiPembayaran(data) {
    const token = this.getToken();
    const request = { 'access_token': token };
    return this.createDataParam(Configuration.BASE_URL + Configuration.VERIFIKASI_PEMBAYARAN, data, request);
  }

  submitRating(data) {
    const token = this.getToken();
    const request = { 'access_token': token };
    return this.createDataParam(Configuration.BASE_URL + Configuration.ADD_RATING, data, request);
  }

  getListBooking(
    page, limit, sortBy, direction, searchBy, searchVal,
    bookingStatus, sessionStartDate, sessionEndDate, startDateCreated,
    endDateCreated, professionalName, isAll, isZoomLinkSent, paymentMethod,
    promoCode: string,
    referralCode: string
  ) {
    const token = this.getToken();
    const request = {
      'page': page,
      'limit': limit,
      'sortBy': sortBy,
      'direction': direction,
      'searchVal': searchVal,
      'startDate': sessionStartDate,
      'endDate': sessionEndDate,
      'startCreatedDate': startDateCreated,
      'endCreatedDate': endDateCreated,
      'bookingStatus': bookingStatus,
      'professionalName': professionalName,
      'isAll': isAll,
      'isZoomLinkSent': isZoomLinkSent,
      'paymentMethod': paymentMethod,
      promoCode,
      referralCode
    };

    return this.getDataHeader(Configuration.BASE_URL + Configuration.LIST_BOOKING, this.removeUndefinedParams(request))
      .pipe(map(post => {
        post['elements'] = post.result.total_elements;
        post.result = post.result.list_data;

        return post;
    }));
  }

  getListBookingByDate(page, limit, sortBy, direction, searchBy, searchVal, startDate, endDate, bookingStatus, isAll) {

    const token = this.getToken();
    const request = {
      'page': page,
      'limit': limit,
      'direction': direction,
      'searchVal': searchVal,
      'startDate': startDate,
      'endDate': endDate,
      'bookingStatus': bookingStatus,
      'isAll': isAll
    };
    return this.getDataHeader(Configuration.BASE_URL + Configuration.LIST_BOOKING, request);
  }

  getListBookingByTime(page, limit, sortBy, direction, searchBy, searchVal, bookingStatus) {
    const token = this.getToken();
    const request = { 'page': page, 'limit': limit,
      'direction': direction, 'searchBy': searchBy, 'searchVal': searchVal, 'bookingStatus': bookingStatus };
    return this.getDataHeader(Configuration.BASE_URL + Configuration.LIST_BOOKING, request);
  }

  updateBookingStatus(data) {
    const token = this.getToken();
    const request = {'access_token': token};
    return this.updateDataParam(Configuration.BASE_URL + Configuration.UPDATE_BOOKING_STATUS, data, data.id, request);
  }

  getListPayment(data1, data2) {
    const request = {'page': data1, 'limit': data2};
    return this.getDataParam(Configuration.BASE_URL + Configuration.PAYMENT_LIST, request);
  }

  getMidtransView(data) {
    const token = this.getToken();
    const request = {'access_token': token};
    return this.createDataParam(Configuration.BASE_URL + Configuration.MIDTRANS_WEBVIEW, data, request);
  }

  updateZoomLinkIsSent(data) {
    return this.updateDataNoIDWithHeader(Configuration.BASE_URL + Configuration.UPDATE_ZOOMLINK_SENT_TRAINER, data);
  }

}
