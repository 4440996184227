import { TopUpBalance } from './../model/balance.model';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseService } from '../base/base-services/base.service';
import { Configuration } from '../base/base-services/configuration';
import { AuthService } from '../base/base-services/auth.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ProfileModel } from '../model/profile.model';
import { ResponseStrongbeeModel } from '../model/response-strongbee.model';


@Injectable()
export class UserService extends BaseService {

  constructor(http: HttpClient, auth: AuthService, localStorageService: LocalStorageService) {
    super(http, localStorageService);
    auth.isAuthenticated();
  }

  addUser(data) {
    return this.createDataHeaderNoParams(Configuration.BASE_URL + Configuration.CREATE_USER, data);
  }

  getUserData(data1) {
    const token = this.getToken();
    const request = { 'access_token': token, 'id': data1 };
    return this.getDataParam(Configuration.BASE_URL + Configuration.DETAIL_USER, request);
  }

  getPaginate(data1, data2, data3) {
    const token = this.getToken();
    const request = { 'access_token': token, 'page': data1, 'limit': data2, 'search': data3 };
    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_USER, request);

  }

  getDetailUser(id) {
    const request = {};
    return this.getDataById<ResponseStrongbeeModel<ProfileModel>>(Configuration.BASE_URL + Configuration.DETAIL_USER, id, request);
  }

  getUserList(
    page: number,
    limit: number,
    active?: boolean,
    keyword?: string
  ) {
    const access_token = this.getToken();
    const search_keyword = `${keyword}-${active}`;
    const request = {
      access_token,
      page,
      limit,
      'searchBy': 'searchWords_words-enabled',
      'searchVal': search_keyword
    };

    // console.log('request ', request);
    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_USER, this.removeUndefinedParams(request));
  }

  v2_getUserList(
    page: number,
    limit: number,
    keywords?: string,
    direction?: string,
    active?: boolean,
  ) {
    const access_token = this.getToken();
    const request = {
      page,
      limit,
      keywords,
      'sort-by': 'created_at',
      direction,
      active
    };

    return this.getDataHeaderNew(Configuration.BASE_URL + Configuration.V2_LIST_USER, request);
  }

  getNonActiveUser(data1, data2, search_name = '') {
    const token = this.getToken();
    const request = {
      'access_token': token,
      'page': data1,
      'limit': data2, 'searchBy':
      'searchWords_words-enabled' ,
      'searchVal': search_name + '-false'
    };

    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_USER, request);
  }

  getActiveUser(data1, data2, search_name = '') {
    const token = this.getToken();
    const request = {
      'access_token': token,
      'page': data1,
      'limit': data2,
      'searchBy': 'searchWords_words-enabled',
      'searchVal': search_name + '-true'
    };


    return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_USER, request);

  }

  uploadImg(data) {
    const token = this.getToken();
    const request = { 'type': 'trainer' };
    return this.createDataParam(Configuration.BASE_URL + Configuration.UPLOAD_IMG, data, request);
  }
  updateUser(data, id) {
    const token = this.getToken();
    const request = { 'access_token': token };
    return this.updateDataParam(Configuration.BASE_URL + Configuration.UPDATE_USER, data, id, request);
  }

  updateStatusUser(data, id) {
    const token = this.getToken();
    const request = { 'access_token': token };
    return this.updateDataParam(Configuration.BASE_URL + Configuration.UPDATE_STATUS_USER, data, id, request);
  }

  topUpBalanceUser(userId, amount, reference, amountType) {
    const payload = {
      'user_id': userId,
      'amount': amount,
      'reference': reference,
      'updated_by': this.getAuthAdminEmail(),
      'amount_type': amountType
    };

    return this.createDataHeaderNoParams(Configuration.BASE_URL + Configuration.TOPUP_WALLET_USER, payload);
  }

  getCurrentBalance(userId) {
    const params = { 'user_id': userId };
    return this.getDataHeader(Configuration.BASE_URL + Configuration.CURRENT_BALANCE_USER, params);
  }

  getHistoryWallet(userId, page, limit) {
    const params = {
      'userId': userId,
      'page': page,
      'limit': limit
    };
    return this.getDataHeader(Configuration.BASE_URL + Configuration.HISTORY_WALLET_USER, params);
  }

   deductBalanceUser(userId, amount, reference, amountType) {
    const payload = {
      'user_id': userId,
      "user_type": "CUSTOMER",
      'amount': amount,
      'booking_code': reference,
      'updated_by': this.getAuthAdminEmail(),
      'payment_type': amountType == 'BPASS' ? amountType : "BFLEXI"
    };

    return this.createDataHeaderNoParams(Configuration.BASE_URL + Configuration.DEDUCT_WALLET_USER, payload);
  }

}
