import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import locId from '@angular/common/locales/id';
import { NgProgressRef } from '@ngx-progressbar/core';
import { HttpLoaderService } from './base/base-services/http-loader.service';
import { BaseComponent } from './base/base-component/base-component';
import { SnotifyService } from 'ng-snotify';


registerLocaleData(locId, 'id');

@Component({
  selector: 'app-root',
  template: `
  <router-outlet>
    <ng-snotify class="material"></ng-snotify>
    <app-spinner></app-spinner>
    <ng-progress #progressBar id="progressBar1" [color]="'#FFE000'" [spinner]="false" [thick]="true"></ng-progress>
  </router-outlet>`,
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'app';

  constructor(private loaderService: HttpLoaderService) {}

  @ViewChild('progressBar', {static: false})
  progress: NgProgressRef;

  ngAfterViewInit() {
    this.loaderService.isLoading.subscribe(loading => {
      loading ? this.progress.start() : this.progress.complete();
    });
  }
}
