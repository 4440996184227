import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-banner-text',
  templateUrl: './widget-banner-text.component.html',
  styleUrls: ['./widget-banner-text.component.css']
})
export class WidgetBannerTextComponent implements OnInit {

  // alert class: info/warning/success/danger
  @Input() alertConfig = {class: '', heading: '', body: ''};

  constructor() { }

  ngOnInit() {
  }

}
