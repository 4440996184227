import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppRoutes } from './app.routing';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './layout/admin/title/title.component';
import { AuthComponent } from './layout/auth/auth.component';
import { CallService } from './base/base-services/call.service';
import { AuthService } from './base/base-services/auth.service';
import { UploadImageService } from './services/upload-image.service';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './base/base-services/auth-guard.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { UserService } from './services/user.service';
import { BookingService } from './services/booking.service';
import { AutoCompleteModule } from 'ng4-auto-complete';
import { CitiesService } from './services/cities.service';
import { TrainerService } from './services/trainer.service';
import { XlsxService } from './services/xlsx.service';
import { EventService } from './services/event.service';
import { HttpLoaderInterceptor } from './base/base-services/interceptor';
import { HttpLoaderService } from './base/base-services/http-loader.service';
import { NgProgressModule } from '@ngx-progressbar/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { environment } from 'environments/environment';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    BreadcrumbsComponent,
    TitleComponent,
    AuthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    ClickOutsideModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    NgxWebstorageModule,
    AutoCompleteModule,
    NgxWebstorageModule.forRoot(),
    NgProgressModule,
    CKEditorModule,
    EditorModule,
    FullCalendarModule,
    AutocompleteLibModule
  ],
  providers: [
    CallService,
    AuthService,
    AuthGuardService,
    AuthenticationService,
    UserService,
    BookingService,
    UploadImageService,
    CitiesService,
    TrainerService,
    EventService,
    XlsxService,
    HttpLoaderService,
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpLoaderInterceptor,
      multi: true,
    },
    ...environment.optionalModules
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
