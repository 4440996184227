import { Component, Input, OnInit, ViewEncapsulation, Output } from '@angular/core';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-modal-animation',
  templateUrl: './modal-animation.component.html',
  styleUrls: ['./modal-animation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalAnimationComponent implements OnInit {

  @Input() modalClass: string;
  @Input() contentClass: string;
  @Input() modalID: string;
  @Input() backDrop = false;
  @Input() show = false;

  constructor() { }

  ngOnInit() {

  }

  close() {
    // document.querySelector('#' + event).classList.remove('md-show');
    this.show = false;
  }
}
