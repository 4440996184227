import { AuthenticateAdmin } from './../enum/authenticate-admin.enum';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseService } from '../base/base-services/base.service';
import { Configuration } from '../base/base-services/configuration';
import { AuthService } from '../base/base-services/auth.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Injectable()
export class AuthenticationService extends BaseService {

	constructor(http: HttpClient, auth: AuthService,localStorageService:LocalStorageService) {
    	super(http,localStorageService);
    	auth.isAuthenticated();
    }
    
    createClient(data) {
        return this.createData(Configuration.BASE_URL + Configuration.REGISTRATION, data);
    };

    sendEmail(id) {
        return this.getData(Configuration.BASE_URL + Configuration.SENDEMAIL + "?id=" + id);
    }

    activateAcc(url) {
        return this.getData(Configuration.BASE_URL + Configuration.ACTIVATECLIENT + "/" + url);
    }

    registrationAcc(data) {
        return this.createData(Configuration.BASE_URL + Configuration.REGISTRATION_CONFIRMATION , data);
    }

    // login(username, password) {
    //     // let request = { "?username": username + "&password": password, "grant_type":"password" }
    //     return this.createDataLoginHeader(Configuration.BASE_URL + Configuration.LOGIN + "?username=" + username + "&password=" + password + "&grant_type=password");
    // }
    login(username, password) {
        let request = { "username": username, "password": password, "grant_type":"password" }  
      
        return this.createDataLoginHeader(Configuration.BASE_URL + Configuration.LOGIN , request);
    }
    logout(data) {
        return this.createData(Configuration.BASE_URL + Configuration.LOGOUT, data);
    }

    sendEmailForgot(data) {
        return this.createData(Configuration.BASE_URL + Configuration.SENDEMAILFORGOT_NEW, data);
    }

    changePassword(data) {
        return this.createDataHeaderNoParams(Configuration.BASE_URL + Configuration.CHANGE_PASS, data);
    }

    getRole() {
        return this.getAuthRole()
    }

    checkTokenActivation(data){
        return this.createData(Configuration.BASE_URL + Configuration.CHECK_TOKEN_ACTIVATION, data)
    }

    submitActivation(data){
        return this.createData(Configuration.BASE_URL + Configuration.SUBMIT_ACTIVATION, data)
    }

    resetPassword(data){
        return this.createData(Configuration.BASE_URL + Configuration.RESET_PASSWORD, data)
    }

    getAuthUserEmail() {
        return this.getAuthAdminEmail();
    }

    private arrayMatch(arr1, arr2) {
        var arr = [];  // Array to contain match elements
      
        for(var i=0 ; i<arr1.length ; ++i) {
          for(var j=0 ; j<arr2.length ; ++j) {
            if(arr1[i] == arr2[j]) {    // If element is in both the arrays
              arr.push(arr1[i]);        // Push to arr array
            }
          }
        }
         
        return arr;  // Return the arr elements
    }

    isAuthenticateImportantUser() {
        return this.arrayMatch([this.getAuthUserEmail()], [AuthenticateAdmin.AdminCitra, AuthenticateAdmin.AdminFarah]).length > 0 ? true : false
      }
}
