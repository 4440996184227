
import {throwError as observableThrowError, Subject, BehaviorSubject } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Configuration } from './configuration';
import * as _ from 'lodash';
import { startsWith } from '@rxweb/reactive-form-validators';
import { JwtHelperService } from '@auth0/angular-jwt';

export abstract class BaseService {

	constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

	protected createHeaderAuth() {
		let headers = new HttpHeaders();
		const token = this.getToken();
		headers = headers.append('Authorization', 'Bearer ' + token);
		headers = headers.append('Content-Type', 'application/json');
		return headers;
	}

	protected createHeaderBearer() {
		let headers = new HttpHeaders();
		const token = this.getToken();
		headers = headers.append('Authorization', 'Bearer ' + token);
		return headers;
	}

	protected createHeaderLogin() {
		let headers = new HttpHeaders();
		// let token = this.getToken();
		headers = headers.append('Authorization', 'Basic c3Ryb25nYmVlOnNlY3JldA==');
		headers = headers.append('Content-Type', 'application/json');
		return headers;
	}

	protected createHeaderAccept() {
		let headers = new HttpHeaders();
		// let token = this.getToken();
		headers = headers.append('Accept', '*');
		headers = headers.append('Content-Type', 'application/json');
		return headers;
	}

	protected getData<T= any>(url) {
		return this.http.get<T>(url).pipe(catchError(this.handleError));
	}

	protected getDataParam<T= any>(url, params) {
		return this.http.get<T>(url, { params: params })
			.pipe(catchError(this.handleError));
	}

	protected getDataEncodedParam<T= any>(url, params) {
		const httpParams = new HttpParams({
			fromObject: params
		});
	
		// manually encode all "+" characters from the param details
		let newurl = url + "?" + httpParams.toString().replace(/\+/gi, '%2B');
	
		return this.http.get<T>(newurl).pipe(catchError(this.handleError));
	}

	protected getDataHeader<T= any>(url, params) {
		return this.http.get<T>(url, {params: params, headers: this.createHeaderAuth() })
			.pipe(catchError(this.handleError));
	}

	protected getDataHeaderNew<T= any>(url, params) {
		return this.http.get<T>(url, {params: params, headers: this.createHeaderBearer()})
			.pipe(catchError(this.handleError));
	}

	protected createData<T= any>(url, data) {
		return this.http.post<T>(url, data)
			.pipe(catchError(this.handleError));
	}

	protected createDataLoginHeader<T= any>(url, params) {
		return this.http.post<T>(url, {}, {headers: this.createHeaderLogin(), params: params});
	}

	protected getDataById<T= any>(url, id, params) {
		return this.http.get<T>(url + '/' + id, {params: params, headers: this.createHeaderAuth()})
			.pipe(catchError(this.handleError));
	}

	protected getDataByIdNoParams<T= any>(url, id) {
		return this.http.get<T>(url + '/' + id, { headers: this.createHeaderAuth() })
			.pipe(catchError(this.handleError));
	}

	protected updateDataById<T= any>(url, data, id, params) {
		return this.http.put<T>(url + '/' + id, {params: params})
			.pipe(catchError(this.handleError));
	}
	protected updateDataByIdWithHeader<T= any>(url, id, data) {
		return this.http.put<T>(url + '/' + id, data, { headers: this.createHeaderAuth()})
			.pipe(catchError(this.handleError));
	}
	protected updateDataNoIDWithHeader<T= any>(url, data) {
		return this.http.put<T>(url, data, { headers: this.createHeaderAuth() })
			.pipe(catchError(this.handleError));
	}
	protected createDataParam<T= any>(url, data, params) {
		return this.http.post<T>(url, data, { params: params })
			.pipe(catchError(this.handleError));
	}
	protected createDataHeader<T= any>(url, data, params) {
		return this.http.post<T>(url, data, {params: params, headers: this.createHeaderAuth()})
			.pipe(catchError(this.handleError));
	}
	protected createDataHeaderNoParams<T= any>(url, data) {
		return this.http.post<T>(url, data, { headers: this.createHeaderAuth() })
			.pipe(catchError(this.handleError));
	}
	protected updateData<T= any>(url, data) {
		return this.http.put<T>(url + '/' + data.id, data)
			.pipe(catchError(this.handleError));
	}

  protected UpdateDataHeaderNoParams<T= any>(url, data) {
    return this.http.put<T>(url, data, { headers: this.createHeaderAuth() })
			.pipe(catchError(this.handleError));
  }

	protected deleteClass<T= any>(url, id, data) {
		return this.http.put<T>(url + '/' + id, data, {headers: this.createHeaderBearer()})
			.pipe(catchError(this.handleError));
	}

	protected updateDataParam<T= any>(url, data, id, params) {
		return this.http.put<T>(url, data, { params: params, headers: this.createHeaderBearer() })
			.pipe(catchError(this.handleError));
	}
	protected deleteData<T= any>(url, data) {
		return this.http.delete<T>(url + '/' + data)
			.pipe(catchError(this.handleError));
	}
	protected deleteDataWithHeader<T= any>(url, data) {
		return this.http.delete<T>(url + '/' + data, {headers: this.createHeaderBearer()})
			.pipe(catchError(this.handleError));
	}

	protected deleteDataIdWithHeader<T= any>(url, id) {
		return this.http.delete<T>(url + '/' + id, {headers: this.createHeaderBearer()})
			.pipe(catchError(this.handleError));
	}

	protected deleteDataWithData<T= any>(url, data) {
		return this.http.request<T>('delete', url, { body: data, headers: this.createHeaderBearer()})
			.pipe(catchError(this.handleError));
	}

	protected deleteDataParam<T= any>(url, params) {
		return this.http.delete<T>(url, { params: params })
			.pipe(catchError(this.handleError));
	}

	protected deleteDataParamWithHeader<T= any>(url, params) {
		return this.http.delete<T>(url, { params: params, headers: this.createHeaderBearer() })
			.pipe(catchError(this.handleError));
	}

	protected getToken() {
		return this.localStorageService.retrieve('token');
	}

	protected generateParamSearch(sData: {[key: string]: string}): [string, string] {
		for (const data in sData) {
			if ((sData[data] || '').length  ===  0) {
			delete sData[data];
			}
		}

		if (Object.keys(sData).length  ===  0) {
			return ['', ''];
		}

		return [Object.keys(sData).join('-'), Object.values(sData).join('-')];
	}

	protected removeUndefinedParams(queryParam: {[key: string]: any}): {[key: string]: any} {
		return _.omitBy(queryParam, e => {
		return e  ===  undefined || e === '';
		}) as any;
	}

	private handleError(error: HttpErrorResponse) {
		return observableThrowError(error);
	}

	protected doRefreshToken() {
		return this.createDataLoginHeader(Configuration.BASE_URL + Configuration.LOGIN, {
			refresh_token : this.localStorageService.retrieve('refresh_token'),
			username: this.localStorageService.retrieve('username'),
			password: this.localStorageService.retrieve('password'),
			grant_type : 'refresh_token'
		}).pipe(catchError(this.handleError));
	}

	protected getAuthAdminEmail() {
		const token = this.getToken();
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(token);

		return decodedToken.user_name;

	}

	protected getAuthRole() {
		const token = this.getToken();
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(token);

		return decodedToken.authorities;

	}
}
