import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpLoaderService } from './http-loader.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpLoaderInterceptor implements HttpInterceptor {

    constructor(private loaderService: HttpLoaderService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.isLoading.next(true);
        this.loaderService.isError.next({
            isError: false, error: {} as HttpErrorResponse
        });

        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.loaderService.isLoading.next(false);
                }
            },
            (err: HttpErrorResponse) => {
                this.loaderService.isLoading.next(false);
                this.loaderService.isError.next({
                    isError: true, error: err
                });
            },
            
        ));
    }


}
