import * as Yup from 'yup';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseService } from '../base/base-services/base.service';
import { Configuration } from '../base/base-services/configuration';
import { AuthService } from '../base/base-services/auth.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable, forkJoin } from 'rxjs';
import { ResponseStrongbeeModel } from '../model/response-strongbee.model';
import { SubCategoryModel } from '../model/subcategory.model';
import { TrainerModel, Expertise, TrainerClass, TrainerScheduleList, TrainerWebEntity } from '../model/trainer.model';
import { RegexPattern } from '../base/base-component/regex-pattern';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { PropValidationConfig } from '@rxweb/reactive-form-validators/models/prop-validation-config';
import { TrainerYupFormValidate } from '../model/form/trainer.form.model';


@Injectable()
export class TrainerService extends BaseService {
    private dtSchema: Yup.ObjectSchema<TrainerModel>;

    constructor(http: HttpClient, auth: AuthService, localStorageService: LocalStorageService,
        private session: SessionStorageService) {
        super(http, localStorageService);
        auth.isAuthenticated();
        this.setValidator();

    }

    private setValidator() {
      const requiredMsg = (fe: Yup.TestMessageParams) => {
        return `${fe.label ? fe.label : fe.path} is Required`;
      };

      this.dtSchema = TrainerYupFormValidate;
    }

    addContact(data) {
        return this.createData(Configuration.BASE_URL, data);
    }

    getSpecialities() {
        const request = { };
        return this.getDataHeader(Configuration.BASE_URL + Configuration.SPECIALITY, request);
    }

    getExpertiseDesc() {
        const request = {};
        return this.getDataHeader(Configuration.BASE_URL + Configuration.EXPERTISE_DESC, request);
    }

    getExpertiseSub() {
        const request = {};
        return this.getDataHeader(Configuration.BASE_URL + Configuration.EXPERTISE_SUB, request);
    }

    getExpertiseSubDesc() {
        const request = {};
        return this.getDataHeader(Configuration.BASE_URL + Configuration.EXPERTISE_SUB_DESC, request);
    }

    getExpertiseSubSub() {
        const request = {};
        return this.getDataHeader(Configuration.BASE_URL + Configuration.EXPERTISE_SUB_SUB, request);
    }

    getRegency(data1, data2, data3) {
        const request = {
            'page': data1,
            'limit': data2,
            'searchBy': 'name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name,name',
            'searchVal': 'jakarta,depok,bekasi,bogor,tangerang,bandung,jembrana,tabanan,badung,gianyar,klungkung,bangli,karang asem,buleleng,denpasar,yogyakarta,sleman,bantul,gunung kidul,kulon progo,cirebon,USA,Australia,Indonesia,Oman,Thailand,Singapore,Borobudur,Jawa Timur, Jawa Tengah, Jawa Barat, Surabaya, Malang, Kabupaten Banyumas'};
        return this.getDataHeader(Configuration.BASE_URL + Configuration.LIST_REGENCY, request);
    }

    getEstablishment2() {
        const token = this.getToken();
        const request = { 'access_token': token };
        return this.getDataParam(Configuration.BASE_URL + Configuration.ESTABLISHMENT_TYPES, request);
    }

    getFacility() {
        const token = this.getToken();
        const request = { 'access_token': token };
        return this.getDataParam(Configuration.BASE_URL + Configuration.FACILITY, request);
    }

    getBank() {
        const token = this.getToken();
        const request = { 'access_token': token };
        return this.getDataParam(Configuration.BASE_URL + Configuration.GET_BANK, request);
    }

    getDetailTrainer(id) {
        const request = {};
        return this.getDataById(Configuration.BASE_URL + Configuration.TRAINER_DETAIL, id, request);
    }

    addTrainer(data) {
        const token = this.getToken();
        const request = { 'access_token': token };
        return this.createDataParam(Configuration.BASE_URL + Configuration.URL_CREATE_TRAINER, data, request);
    }

    addEstablish(data) {
        const token = this.getToken();
        const request = { 'access_token': token };
        return this.createDataParam(Configuration.BASE_URL + Configuration.CREATE_ESTABLISH, data, request);
    }

    editEstablish(data, id) {
        const token = this.getToken();
        const request = { 'access_token': token };
        return this.updateDataParam(Configuration.BASE_URL + Configuration.EDIT_ESTABLISH, data, id, request);
    }

    getPaginateNoGender(data1, data2, data3, data4, data5, data6, data7, is_prof) {
        const request = { 'page': data1, 'limit': data2, 'name': data3,
            'expertise': data4, 'isBooking': data5, 'location': data6, 'active': data7,
            'isProfessional': is_prof };

        return this.getDataParam(Configuration.BASE_URL + Configuration.TRAINER_LIST, request);
    }

    getPaginate(page, limit, name, expertise, isBooking, gender, location, active, is_prof, is_gender= false) {
        const request = {'page': page, 'limit': limit, 'name': name, 'gender': gender, 'active': active};
        if (is_gender) {
            Object.assign(request, {
                'gender': gender,
                'location': location,
                'expertise': expertise,
                'isBooking': isBooking,
                'isProfessional': is_prof
            });
        }
        return this.getDataParam(Configuration.BASE_URL + Configuration.TRAINER_LIST, this.removeUndefinedParams(request));
    }

    getListProfessional(data1, data2) {
        const token = this.getToken();
        const request = {'access_token': token, 'page': data1, 'limit': data2, 'sort-by': 'name', 'direction': 'asc', 'searchBy': 'isBooking', 'searchVal': 'true'};
        return this.getDataParam(Configuration.BASE_URL + Configuration.TRAINER_LIST, request);
    }

    getEstablishment(data1, data2, data3) {
        const token = this.getToken();
        const request = {'access_token': token, 'page': data1, 'limit': data2, 'searchBy': 'name-active', 'searchVal': data3};
        return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_ESTABLISHMENT, request);
    }

    getEstablishmentDetail(data) {
        const token = this.getToken();
        const request = { 'access_token': token, 'id': data };
        return this.getDataParam(Configuration.BASE_URL + Configuration.DETAIL_ESTABLISHMENT, request);
    }

    getTrainerById(id) {
      const token = this.getToken();
      const request = {'access_token': token, 'id': id};
      return this.getDataParam(Configuration.BASE_URL + Configuration.TRAINER_DETAIL, request);
    }

    updateTrainer( data,  id) {
      const token = this.getToken();
      const request = {'access_token': token};
      return this.updateDataParam<ResponseStrongbeeModel>(Configuration.BASE_URL + Configuration.UPDATE_TRAINER, data, id, request);
    }

    updateTrainerX(data) {
      const token = this.getToken();
      const request = {'access_token': token};
      return this.updateData(Configuration.BASE_URL + Configuration.UPDATE_TRAINER,  request);
    }

    validateDeleteClass(id, data) {
        return this.deleteClass(Configuration.BASE_URL + Configuration.DELETE_CLASS, id, data);
    }

    getShiftTrainer() {
        const token = this.getToken();
        const request = { 'access_token': token};
        return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_SHIFT, request);
    }

    uploadImg(data) {
      const token = this.getToken();
      const request = { 'access_token': token};
      return this.createDataParam(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO, data, request);
    }

    uploadMultipleImgs(data1, data2): Observable<any[]> {
        const token = this.getToken();
        const request = { 'access_token': token };
        const response1 = this.createDataParam(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO, data1, request);
        const response2 = this.createDataParam(Configuration.BASE_URL + Configuration.UPLOAD_IMG_PHOTO, data2, request);
        return forkJoin([response1, response2]);
    }

    getDetailShiftTrainer(id, day, book_date) {
        const token = this.getToken();
        const request = { 'access_token': token, 'id': id, 'day': day, 'book_date': book_date };
        return this.getDataParam(Configuration.BASE_URL + Configuration.GET_DETAIL_SHIFT, request);
    }

    getList(data1, data2) {
        const token = this.getToken();
        const request = {'access_token': token, 'page': data1, 'limit': data2};
        return this.getDataParam(Configuration.BASE_URL + Configuration.TRAINER_LIST, request);
    }

    getProfessionalDetail(id) {
        const token = this.getToken();
        const request = {'access_token': token};
        return this.getDataParam(Configuration.BASE_URL + Configuration.TRAINER_DETAIL + '/' + id, request);
    }

    getListTags(searchData) {
        const request = {'searchBy': 'name', 'searchVal': searchData};
        return this.getDataParam(Configuration.BASE_URL + Configuration.LIST_TAG, request);
    }

    doValidate(md: TrainerModel) {
        return this.dtSchema.validate(md, {'context': {data: md}});
    }
}
